import { useCallback, useState, useEffect, FC, useMemo, createContext, ReactNode, useRef } from 'react';
import { ga } from '@my-account/tools';
import { Button, Modal, Select, ThemeProvider } from '@myob/myob-widgets';
import myAccountLogo from 'assets/images/myAccountLogo.svg';
import { BodyWrapper, HeaderWrapper } from './styles';
import LoadingWrapper from 'components/LoadingWrapper';
import { useAccountSelectorState } from 'hooks/useAccountSelectorState';
import { AccountSelectorState } from 'helpers/AccountSelectorService';
import { HttpStatus } from 'helpers/request';

type Props = {
  children: ReactNode;
};

export type AccountSelectContextValue = Pick<AccountSelectorState, 'selected' | 'error' | 'reason' | 'isSoloAccount'>;

export const AccountSelectContext = createContext<AccountSelectContextValue>({
  selected: null,
  error: null,
  reason: '',
  isSoloAccount: false,
});

export const AccountSelectWrapper: FC<Props> = ({ children }) => {
  const [selectedAccount, setSelectedAccount] = useState<string>('');
  const [{ isLoading, accountList, error, selected, reason, isSoloAccount }, setSelected] = useAccountSelectorState();

  const paramsRef = useRef<URLSearchParams>(new URLSearchParams(window.location.search));

  useEffect(() => {
    if (!accountList.length) return;
    const accountId = paramsRef.current.get('accountId');
    paramsRef.current.delete('accountId');
    let paramString = paramsRef.current.toString();
    !paramString || (paramString = `?${paramString}`);
    history.replaceState(null, '', `${window.location.pathname}${paramString}`);
    setSelectedAccount(accountList[0].clientId);
    if (accountList.length === 1) {
      setSelected(accountList[0].clientId, 'queryString');
    } else if (accountId) {
      setSelected(accountId, 'queryString');
    }
  }, [accountList, setSelected]);

  const handleAccountChange = useCallback((value) => {
    setSelectedAccount(value);
  }, []);
  const handleGoToMyAccountButton = useCallback(() => {
    setSelected(selectedAccount);
    ga.push({
      event: 'modalClosed',
      modalName: 'AccountSelector',
    });
  }, [selectedAccount, setSelected]);

  const Selector = useCallback(() => {
    return (
      <Select
        data-testid="accountSelect"
        value={selectedAccount}
        label="Please choose the account you would like to access"
        name="account-selector"
        className="my-account-account-selector"
        onChange={({ target }) => handleAccountChange(target.value)}
      >
        {accountList.map((item) => (
          <Select.Option key={item.clientId} label={`${item.accountName} - ${item.clientId}`} value={item.clientId} />
        ))}
      </Select>
    );
  }, [accountList, handleAccountChange, selectedAccount]);
  const contextValue = useMemo(
    () => ({ selected, error, reason: reason, isSoloAccount }),
    [error, selected, reason, isSoloAccount],
  );
  const showModal = !error && !selected && accountList.length > 1;
  const showChildren = error || selected;

  useEffect(() => {
    showModal &&
      ga.push({
        event: 'modalOpened',
        modalName: 'AccountSelector',
      });
  }, [showModal]);

  useEffect(() => {
    error &&
      ga.push({
        event: 'contentViewed',
        elementId: error.status === HttpStatus.Forbidden ? 'Denied_EmptyAccount' : `Denied_${error.status}`,
      });
  }, [error]);

  return (
    <ThemeProvider>
      <LoadingWrapper isLoading={isLoading}>
        {showModal && (
          <Modal
            data-testid="accountSelectModal"
            header={
              <HeaderWrapper>
                <img src={myAccountLogo} alt="myaccount logo" />
              </HeaderWrapper>
            }
            canClose={false}
          >
            <Modal.Body>
              <BodyWrapper>
                <h2>Welcome Back!</h2>
                {Selector()}
              </BodyWrapper>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={handleGoToMyAccountButton}>Go to my account</Button>
            </Modal.Footer>
          </Modal>
        )}
        {showChildren && <AccountSelectContext.Provider value={contextValue}>{children}</AccountSelectContext.Provider>}
      </LoadingWrapper>
    </ThemeProvider>
  );
};
