import styled from '@emotion/styled';

export const HeaderWrapper = styled.div`
  position: relative;
  height: 22px;

  img {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
`;

export const BodyWrapper = styled.div`
  text-align: center;

  & div {
    text-align: center;
  }
`;
