import { Account, fetchAccounts, fetchProductStatusByClientId } from './api';
import { BehaviorSubject, Subject } from 'rxjs';
import { ResponseError } from './request';
import { ONE_DAY_IN_MS, SELECTED_ACCOUNT_ID_KEY } from './constants';

export type AccountSelectorState = {
  isLoading: boolean;
  accountList: Account[];
  selected: Account | null;
  error: ResponseError | null;
  reason?: string;
  isSoloAccount?: boolean;
};
export class AccountSelectorService {
  private readonly subject: Subject<AccountSelectorState>;
  private accountSelectorState: AccountSelectorState = {
    isLoading: true,
    accountList: [],
    selected: null,
    error: null,
    reason: '',
    isSoloAccount: false,
  };

  constructor() {
    this.subject = new BehaviorSubject(this.accountSelectorState);
    this.getAccountList();
  }

  getSubject = () => {
    return this.subject;
  };

  setSelected = async (clientId: Account['clientId'], reason = '') => {
    const { accountList, selected } = this.accountSelectorState;
    const account = accountList.find((item) => clientId === item.clientId);

    const productInfo = await this.handleProductInfo(clientId);
    const isSoloAccount = productInfo?.product?.solo ?? false;
    this.handleMocaForSolo(isSoloAccount);

    if (!account) {
      localStorage.removeItem(SELECTED_ACCOUNT_ID_KEY);
      this.accountSelectorState.selected = null;
      this.accountSelectorState.reason = reason;
      this.accountSelectorState.isSoloAccount = false;
      this.publish();
    } else if (selected?.clientId !== clientId) {
      localStorage.setItem(
        SELECTED_ACCOUNT_ID_KEY,
        JSON.stringify({
          clientId: account.clientId,
          expireTime: Date.now() + ONE_DAY_IN_MS,
          isSoloAccount: isSoloAccount,
        }),
      );
      this.accountSelectorState.selected = account;
      this.accountSelectorState.reason = reason;
      this.accountSelectorState.isSoloAccount = isSoloAccount;
      this.publish();
    }
  };

  private handleProductInfo = async (clientId: Account['clientId']) => {
    const productInfo = await fetchProductStatusByClientId(clientId);
    if (productInfo && 'product' in productInfo) {
      localStorage.setItem('isPremiumCustomer', productInfo.product.premium ? 'true' : 'false');
    } else {
      localStorage.removeItem('isPremiumCustomer');
    }
    return productInfo;
  };

  private handleMocaForSolo = (isSoloAccount = false) => {
    const element = document.getElementById('virtual-assistant');
    if (element) {
      if (isSoloAccount) {
        element.style.display = 'none';
      } else {
        element.style.display = 'block';
      }
    }
  };

  private getAccountList = async () => {
    try {
      const search = new URLSearchParams(window.location.search);
      const accountId = search.get('accountId');
      const storedAccount = JSON.parse(localStorage.getItem(SELECTED_ACCOUNT_ID_KEY) || 'null');
      let isSoloAccount = storedAccount ? storedAccount.isSoloAccount : false;
      const accounts = await fetchAccounts();
      let selected = null;
      if (accountId) {
        selected = accounts.find((account) => account.clientId === accountId) || null;
        const productInfo = await this.handleProductInfo(accountId);
        isSoloAccount = productInfo?.product?.solo ?? false;
        localStorage.setItem(
          SELECTED_ACCOUNT_ID_KEY,
          JSON.stringify({ clientId: accountId, expireTime: Date.now() + ONE_DAY_IN_MS, isSoloAccount: isSoloAccount }),
        );
      } else if (storedAccount) {
        storedAccount.expireTime > Date.now() &&
          (selected = accounts.find((account) => account.clientId === storedAccount.clientId) || null);
      }

      this.handleMocaForSolo(isSoloAccount);
      this.accountSelectorState = {
        ...this.accountSelectorState,
        isLoading: false,
        accountList: accounts,
        selected,
        isSoloAccount: isSoloAccount,
      };
    } catch (e: any) {
      this.accountSelectorState = {
        ...this.accountSelectorState,
        selected: null,
        accountList: [],
        isLoading: false,
        error: e,
      };
    } finally {
      this.publish();
    }
  };
  private publish = () => {
    this.subject.next(this.accountSelectorState);
  };
}

let service: AccountSelectorService;

export const getAccountSelectorService = () => {
  return service || (service = new AccountSelectorService());
};
