import { useState, useMemo, useEffect } from 'react';
import { AccountSelectorState, getAccountSelectorService } from 'helpers/AccountSelectorService';

export const useAccountSelectorState = () => {
  const [accountSelectorState, setAccountSelectorState] = useState<AccountSelectorState>({
    isLoading: true,
    accountList: [],
    selected: null,
    error: null,
    reason: '',
  });
  const { getSubject, setSelected } = useMemo(() => getAccountSelectorService(), []);
  useEffect(() => {
    const subscription = getSubject().subscribe({
      next: (accountState) => {
        setAccountSelectorState({ ...accountState });
      },
    });
    return () => subscription.unsubscribe();
  }, [getSubject]);
  return [accountSelectorState, setSelected] as const;
};
