import { auth, ga, GtmManager } from '@my-account/tools';
import { appConfig } from 'config';

export interface ResponseError {
  status?: HttpStatus;
  message?: string;
}

export enum HttpStatus {
  BadRequest = 400,
  Forbidden = 403,
  Unauthorized = 401,
  ServerError = 500,
}

export const getRequestUrl = (requestPath: string): string => `${appConfig.API_BASE_URL}${requestPath}`;

function pushGTMEvent(httpMethod: string, apiPath: string, status: number) {
  GtmManager.dataLayer({
    dataLayer: {
      event: 'API_Request',
      api_url: `${httpMethod} ${apiPath}`,
      http_status: status,
    },
    dataLayerName: 'MyAccount',
  });
}

export const request = async <T>(requestPath: string, init?: RequestInit): Promise<T> => {
  const token = await auth.getToken();
  return new Promise<T>((resolve, reject) => {
    const initWithToken: RequestInit = {
      ...init,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const requestUrl = getRequestUrl(requestPath);

    const apiPath = requestUrl.split('?')[0];

    let httpMethod = 'GET';
    if (initWithToken.method) httpMethod = initWithToken.method;

    fetch(requestUrl, initWithToken)
      .then(async (res) => {
        const { status } = res;

        pushGTMEvent(httpMethod, apiPath, status);

        if (status === HttpStatus.Unauthorized) {
          return auth.login();
        }

        if (!res.ok) {
          reject({ status } as ResponseError);
        }

        const body = await res.json();
        resolve(body);
      })
      .catch(reject);
  });
};
