import { request, HttpStatus } from './request';
import { Region } from '@my-account/tools';

export type Account = {
  accountName: string;
  region: Region;
  clientId: string;
};

export type PremiumSupportInfo = {
  product: {
    premium?: boolean;
    solo?: boolean;
  };
};

let accountList: Account[];

export const fetchAccounts = async (): Promise<Account[]> => {
  accountList = await request<Account[]>('/api/account').catch((error) => {
    throw error;
  });
  if (accountList.length < 1) {
    throw { status: HttpStatus.Forbidden };
  }
  return accountList;
};

export const fetchProductStatusByClientId = async (clientId: Account['clientId']): Promise<PremiumSupportInfo> => {
  return request<PremiumSupportInfo>(`/api/product-status?clientId=${clientId}`);
};
